var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sm-editable-item',{attrs:{"validatePermission":false,"disabledAllFields":true,"isLoading":_vm.isLoadingPage,"breadcrumbs":_vm.breadcrumbs,"pageHeader":_vm.pageHeader,"fields":_vm.tableFields},on:{"cancel":_vm.onCancel},scopedSlots:_vm._u([{key:"tab-top-panel-0",fn:function(){return [_c('sm-editable-form',{attrs:{"fields":_vm.formFields[0].form,"disabledAllFields":true},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})]},proxy:true},{key:"table-publications-releasePublicationInfo",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"editable-item__link",attrs:{"to":{
        name: 'ReleasePublicationInfo',
        params: {
          deployId: _vm.$route.params.deployId,
          releaseId: row.releaseId,
          id: row.publicationId,
        },
      }}},[_vm._v(" Просмотреть ")])]}}]),model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})}
var staticRenderFns = []

export { render, staticRenderFns }