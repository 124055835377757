<template>
  <sm-editable-item
    v-model="form"
    :validatePermission="false"
    :disabledAllFields="true"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="tableFields"
    @cancel="onCancel"
  >
    <template #tab-top-panel-0>
      <sm-editable-form
        v-model="form"
        :fields="formFields[0].form"
        :disabledAllFields="true"
      />
    </template>

    <template v-slot:table-publications-releasePublicationInfo="{ row }">
      <router-link
        class="editable-item__link"
        :to="{
          name: 'ReleasePublicationInfo',
          params: {
            deployId: $route.params.deployId,
            releaseId: row.releaseId,
            id: row.publicationId,
          },
        }"
      >
        Просмотреть
      </router-link>
    </template>
  </sm-editable-item>
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('deploy');
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import SmEditableForm from '@/components/common/forms/SmEditableForm.vue';

export default {
  name: 'ReleaseInfo',

  components: {
    SmEditableItem,
    SmEditableForm,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      refreshPageInterval: null,
      form: {},
    };
  },

  computed: {
    ...mapState(['releaseInfo']),

    pageHeader() {
      return `Информации по релизу ${this.$route.params.id}`;
    },

    breadcrumbs() {
      return [
        {
          text: 'Публикация приложений',
          route: { name: 'PublishingApplicationsMain' },
        },
        {
          text: 'Проекты',
          route: { name: 'Deploy' },
        },
        {
          text: `${this.form.projectName}`,
          route: {
            name: 'DeployDetail',
            params: { id: this.$route.params.deployId },
          },
        },
        {
          text: this.pageHeader,
        },
      ];
    },

    formFields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'projectName',
              label: 'Название проекта',
            },
            {
              type: 'text',
              key: 'created',
              label: 'Дата добавления',
            },
            {
              type: 'text',
              key: 'author',
              label: 'Автор',
            },
            {
              type: 'text',
              key: 'status',
              label: 'Статус публикации',
            },
          ],
        },
      ];
    },

    tableFields() {
      return [
        {
          table: {
            caption: 'Информация о развертывании',
            headers: [
              {
                text: 'Название публикации',
                alias: 'publicationName',
              },
              {
                text: 'Дата создания релиза',
                alias: 'releaseCreated',
              },
              {
                text: 'Статус развертывания',
                alias: 'status',
              },
              {
                text: 'Дата изменения статуса',
                alias: 'statusMoment',
              },
              {
                text: 'Информации о публикации релиза',
                alias: 'releasePublicationInfo',
              },
            ],
            items: this.form.publications || [],
            key: 'publications',
          },
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    this.fetchReleaseInfo().finally(() => {
      this.isLoadingPage = false;
    });
    this.refreshPageInterval = setInterval(this.fetchReleaseInfo, 10000);
  },

  beforeDestroy() {
    clearInterval(this.refreshPageInterval);
  },

  methods: {
    ...mapActions(['getReleaseInfo']),

    fetchReleaseInfo() {
      return this.getReleaseInfo({ releaseId: this.id }).then(() => {
        this.form = this.lodash.cloneDeep(this.releaseInfo.data);
      });
    },

    onCancel() {
      this.$router.push({
        name: 'DeployDetail',
        params: { id: this.$route.params.deployId },
      });
    },
  },
};
</script>

<style lang="scss">
.editable-item__link {
  text-decoration: underline;
  color: var(--blue);

  cursor: pointer;
}
</style>
